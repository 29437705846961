import React from "react"

const MeasageIcon = () => {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_223_12065)">
        <path
          d="M37.7354 17.044C37.7354 8.88643 31.4987 2.27368 19.9988 2.27368C8.49891 2.27368 2.26367 8.89245 2.26367 17.044C2.26367 24.776 7.87043 31.1164 18.2399 31.7572L18.7915 34.9884C18.816 35.1297 18.88 35.2613 18.976 35.368C19.072 35.4747 19.1963 35.5523 19.3345 35.5917C19.4726 35.6311 19.6191 35.6308 19.7572 35.5909C19.8952 35.551 20.0192 35.473 20.1148 35.3659L23.4744 31.5933C32.7496 30.3884 37.7354 24.3352 37.7354 17.044Z"
          stroke="#FAFAFA"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8301 12.8842H27.1697"
          stroke="#FAFAFA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8301 21.2211H21.132"
          stroke="#FAFAFA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_223_12065">
          <rect width="40" height="37.8947" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MeasageIcon
